import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import dayjs from 'dayjs';
import { imageUrl } from 'theme';
import xss from 'xss';
import buildConfig from './buildConfig';

function createOrganisationJsonLd() {
  let jsonLd = {};

  try {
    jsonLd = {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      url: 'https://seedly.sg/',
      logo: imageUrl.seedlyLogoSchema,
      legalName: 'Seedly',
      description:
        'At Seedly, we help you make smarter financial decisions with the right tools and the right mindset',
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'Singapore',
        postalCode: '139954',
        streetAddress: '77 Ayer Rajah Crescent, #03-23',
      },
      sameAs: [
        'https://twitter.com/seedlysg',
        'https://www.instagram.com/seedlysg',
        'https://www.facebook.com/seedlysg',
        'https://t.me/personalfinancesg',
        'https://www.linkedin.com/company/seedly/?originalSubdomain=sg',
        'https://www.youtube.com/channel/UCKtP6p9I7bTumjylWIcxXaA',
      ],
    };
  } catch (error) {
    jsonLd = {};
  }

  return JSON.stringify(jsonLd);
}

function createQuestionsAnswersJsonLd(question, answers) {
  let jsonLd = {};

  try {
    const { acceptedAnswer, suggestedAnswers } = answers;
    const questionUrl = `${buildConfig.apiHost}/posts/${question.slug}`;
    const questionTags = question.post.tags.join(', ');
    const questionTitle = xss(question.title);
    const questionDescription = xss(question.description);
    const questionAuthor = question.isAnonymous
      ? 'Anonymous Question Poster'
      : xss(question.user.name);
    const mainImage = `${buildConfig.s3BucketEndpoint}/share/og.png`;

    const base = {
      '@context': 'http://schema.org',
      '@type': 'QAPage',
      name: questionTitle,
      description: questionDescription,
      image: mainImage,
      primaryImageOfPage: {
        '@context': 'http://schema.org',
        '@type': 'ImageObject',
        name: mainImage,
      },
    };

    const mainEntity = {
      '@context': 'http://schema.org',
      '@type': 'Question',
      '@id': questionUrl,
      name: questionTitle,
      text: questionDescription,
      upvoteCount: question.post.upvotesCount,
      dateCreated: dayjs(question.createdAt).format('YYYY-MM-DDTHH:mmZ'),
      dateModified: dayjs(question.updatedAt).format('YYYY-MM-DDTHH:mmZ'),
      author: {
        '@type': 'Person',
        name: questionAuthor,
      },
      answerCount: question.answersCount,
      keywords: questionTags,
      about: questionTags,
      description: questionTitle,
      isPartOf: questionTitle,
      url: questionUrl,
      image: mainImage,
    };

    let mainEntityJsonLd = mainEntity;
    if (suggestedAnswers.length > 0) {
      mainEntityJsonLd = merge(mainEntityJsonLd, {
        suggestedAnswer: suggestedAnswers.map(suggestedAnswer => {
          const suggestedAnswerText = xss(suggestedAnswer.answerText);
          const suggestedAnswerAuthor = suggestedAnswer.isAnonymous
            ? 'Anonymous Answerer'
            : xss(suggestedAnswer.user.name);

          return {
            '@context': 'http://schema.org',
            '@type': 'Answer',
            '@id': `${buildConfig.apiHost}/posts/${question.slug}?aid=${suggestedAnswer.id}`,
            name: suggestedAnswerText,
            text: suggestedAnswerText,
            upvoteCount: suggestedAnswer.upvotesCount,
            dateCreated: dayjs(suggestedAnswer.createdAt).format(
              'YYYY-MM-DDTHH:mmZ',
            ),
            dateModified: dayjs(suggestedAnswer.updatedAt).format(
              'YYYY-MM-DDTHH:mmZ',
            ),
            author: {
              '@type': 'Person',
              name: suggestedAnswerAuthor,
            },
            keywords: questionTags,
            about: questionTags,
            url: `${buildConfig.apiHost}/posts/${question.slug}?aid=${suggestedAnswer.id}`,
            commentCount: acceptedAnswer.commentsCount,
          };
        }),
      });

      if (!isEmpty(acceptedAnswer)) {
        const acceptedAnswerText = xss(acceptedAnswer.answerText);
        const acceptedAnswerAuthor = acceptedAnswer.isAnonymous
          ? 'Anonymous Answerer'
          : xss(acceptedAnswer.user.name);

        mainEntityJsonLd = merge(mainEntityJsonLd, {
          acceptedAnswer: {
            '@type': 'Answer',
            '@id': `${buildConfig.apiHost}/posts/${question.slug}?aid=${acceptedAnswer.id}`,
            name: acceptedAnswerText,
            text: acceptedAnswerText,
            upvoteCount: acceptedAnswer.upvotesCount,
            dateCreated: dayjs(acceptedAnswer.createdAt).format(
              'YYYY-MM-DDTHH:mmZ',
            ),
            dateModified: dayjs(acceptedAnswer.updatedAt).format(
              'YYYY-MM-DDTHH:mmZ',
            ),
            author: {
              '@type': 'Person',
              name: acceptedAnswerAuthor,
            },
            keywords: questionTags,
            about: questionTags,
            url: `${buildConfig.apiHost}/posts/${question.slug}?aid=${acceptedAnswer.id}`,
            commentCount: acceptedAnswer.commentsCount,
          },
        });
      }
    }

    jsonLd = merge(base, { mainEntity: mainEntityJsonLd });
  } catch (error) {
    jsonLd = {};
  }

  return JSON.stringify(jsonLd);
}

function createOpinionJsonLd(opinion) {
  let jsonLd = {};

  try {
    const {
      title,
      description,
      image,
      content,
      user,
      createdAt,
      updatedAt,
      slug,
    } = opinion;
    jsonLd = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      inLanguage: 'en-SG',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `${buildConfig.apiHost}/opinions/${slug}`,
      },
      headline: title,
      description,
      image,
      author: user.name,
      datePublished: dayjs(createdAt).format('YYYY-MM-DDT'),
      dateCreated: dayjs(createdAt).format('YYYY-MM-DDT'),
      dateModified: dayjs(updatedAt).format('YYYY-MM-DDT'),
      publisher: {
        '@type': 'Organization',
        name: 'Seedly',
        url: 'https://seedly.sg/',
        logo: {
          '@type': 'ImageObject',
          url: imageUrl.seedlyLogoSchema,
        },
      },
      articleBody: content,
    };
  } catch (error) {
    jsonLd = {};
  }

  return JSON.stringify(jsonLd);
}

function createSignUpBonusProductsJsonLd(products) {
  let jsonLd = {};

  try {
    const productsJsonLd = products.map(product => {
      return {
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: product.name,
        image: product.logo,
        aggregateRating: {
          '@type': 'AggregateRating',
          bestRating: '5',
          ratingValue: String(product.rating),
          reviewCount: String(product.reviewsCount),
        },
      };
    });

    jsonLd = productsJsonLd;
  } catch (error) {
    jsonLd = {};
  }

  return JSON.stringify(jsonLd);
}

function createProductReviewJsonLd(product, reviews, category) {
  let jsonLd = {};

  try {
    const base = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: product.name,
      image: product.logo,
      description: `${product.name} ${category.name}`,
      brand: {
        '@type': 'Brand',
        name: product.company.name,
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        bestRating: '5',
        ratingValue: String(product.rating),
        reviewCount: String(product.reviewsCount),
      },
    };

    const reviewJsonLd = reviews.map(review => {
      const reviewUsername = xss(review.user.name);
      const reviewComment = xss(review.reviewText);
      return {
        '@type': 'Review',
        author: {
          '@type': 'Person',
          name: reviewUsername,
        },
        reviewRating: {
          '@type': 'Rating',
          ratingValue: String(review.rating),
          bestRating: '5',
        },
        datePublished: dayjs(review.createdAt).format('YYYY-MM-DDTHH:mmZ'),
        reviewBody: reviewComment,
      };
    });

    if (product.reviewsCount === 0) {
      jsonLd = base;
    } else {
      jsonLd = merge(base, { review: reviewJsonLd });
    }

    if (product.details.faqs?.length > 0) {
      const faqJsonLd = {
        '@context': 'http://schema.org',
        '@type': 'FAQPage',
        mainEntity: product.details.faqs.map(faq => ({
          '@type': 'Question',
          name: faq.question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: faq.answer,
          },
        })),
      };
      jsonLd = [jsonLd, faqJsonLd];
    }
  } catch (error) {
    jsonLd = {};
  }

  return JSON.stringify(jsonLd);
}

function createProfileJsonLd(userName, latestEmploymentCredential) {
  let jsonLd = {};

  try {
    jsonLd = {
      '@context': 'http://schema.org',
      '@type': 'Person',
      name: userName,
      ...(!isEmpty(latestEmploymentCredential) && {
        jobTitle: latestEmploymentCredential.desc,
      }),
    };
  } catch (error) {
    jsonLd = {};
  }

  return JSON.stringify(jsonLd);
}

function createPlansJsonLd(plans = []) {
  let jsonLd = {};

  try {
    jsonLd = {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      ItemListElement: plans,
      itemListOrder: 'http://schema.org/ItemListOrderUnordered',
      name: 'Best Electricity Retailers In Singapore',
    };
  } catch (error) {
    jsonLd = {};
  }

  return JSON.stringify(jsonLd);
}

export {
  createOrganisationJsonLd,
  createQuestionsAnswersJsonLd,
  createOpinionJsonLd,
  createProductReviewJsonLd,
  createSignUpBonusProductsJsonLd,
  createProfileJsonLd,
  createPlansJsonLd,
};
