import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { Colors } from 'app/seedly-component-library';
import buildConfig from 'app/utils/buildConfig';
import { homeSeoMeta } from 'app/utils/seoMetaConfig';
import isEmpty from 'lodash/isEmpty';

const propTypes = {
  pageTitle: PropTypes.string,
  canonicalUrl: PropTypes.string,
  ogType: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
  ogUrl: PropTypes.string,
  ogUpdatedTime: PropTypes.string,
  keywords: PropTypes.string,
  is404: PropTypes.bool,
  jsonLd: PropTypes.string,
  postfix: PropTypes.string,
  description: PropTypes.string,
  pageTitleHasPostfix: PropTypes.bool,
  isNoIndex: PropTypes.bool,
};

const defaultProps = {
  pageTitle: '',
  ogType: 'website',
  ogTitle: homeSeoMeta.ogTitle,
  ogDescription: homeSeoMeta.ogDescription,
  ogImage: homeSeoMeta.ogImage,
  ogUrl: null,
  keywords: homeSeoMeta.keywords,
  is404: false,
  postfix: 'Seedly',
  pageTitleHasPostfix: true,
};

class PageHead extends React.Component {
  constructor(props) {
    super(props);
    this.renderOgUrl = this.renderOgUrl.bind(this);
  }

  renderOgUrl() {
    const { is404, ogUrl } = this.props;

    if (ogUrl) {
      return <meta property="og:url" content={ogUrl} />;
    }

    if (is404) {
      return <meta property="og:url" content={buildConfig.apiHost} />;
    }

    return null;
  }

  render() {
    const {
      pageTitle,
      canonicalUrl,
      ogTitle,
      ogDescription,
      ogImage,
      keywords,
      jsonLd,
      postfix,
      pageTitleHasPostfix,
      isNoIndex,
      description,
      verticalCode,
      pageType,
    } = this.props;

    const pageTitleText = pageTitleHasPostfix
      ? `${pageTitle}${postfix}`
      : pageTitle;

    return (
      <Head>
        <title>{pageTitleText}</title>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <meta name="author" content="Seedly" />
        <meta name="keywords" content={keywords} />
        <meta property="og:site_name" content="Seedly" />
        <meta property="og:title" content={ogTitle} />
        <meta
          property="og:description"
          name="description"
          // strip all line breaks
          content={ogDescription.replace(/(\r\n\t|\n|\r\t)/gm, '')}
        />
        {description && (
          <meta
            name="description"
            // strip all line breaks
            content={description.replace(/(\r\n\t|\n|\r\t)/gm, '')}
          />
        )}
        <meta property="og:image" content={ogImage} />
        <meta property="fb:app_id" content={buildConfig.facebookAppId} />
        <meta property="fb:page_id" content={buildConfig.facebookPageId} />
        <meta property="og:type" content={this.props.ogType} />
        <meta
          property="og:updated_time"
          content={this.props.ogUpdatedTime || new Date().toISOString()}
        />
        <meta name="theme-color" content={Colors.seedlyBlue} />
        {this.renderOgUrl()}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content={ogImage} />
        {!isEmpty(jsonLd) && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: jsonLd }}
          />
        )}
        {isNoIndex && <meta name="robots" content="noindex" />}
        {verticalCode && pageType && (
          <>
            <meta name="page_category" content={pageType} />
            <meta name="vertical_code" content={verticalCode} />
          </>
        )}
      </Head>
    );
  }
}

PageHead.propTypes = propTypes;
PageHead.defaultProps = defaultProps;

export default PageHead;
