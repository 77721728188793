import buildConfig from './buildConfig';

const defaultSeoMeta = {
  ogType: 'website',
  ogTitle: 'Seedly',
  ogImage: `${buildConfig.s3BucketEndpoint}/share/og.png`,
  ogDescription:
    'Manage your money, track your progress and learn to make smarter financial decisions with Seedly.',
  keywords: 'personal finance, financial product reviews, track expenses',
};

const homeSeoMeta = {
  ogType: 'website',
  ogTitle: 'Seedly - Personal Finance Community & Free Expense Tracker',
  ogDescription:
    'Manage your money, track your progress and learn to make smarter financial decisions with Seedly.',
  ogImage: `${buildConfig.s3BucketEndpoint}/share/home.png`,
  keywords: 'personal finance, financial product reviews, track expenses',
};

const appSeoMeta = {
  ogTitle: 'Expense Tracker for Singaporeans - Seedly',
  ogDescription:
    'Free expense tracker to help Singaporeans save on living expenses and reduce debt faster than other budgeting methods.',
  ogImage: `${buildConfig.s3BucketEndpoint}/share/app.png`,
  keywords:
    'expense tracker, budget tracker, finance tracker, personal finance app, money management',
};

const qnaSeoMeta = {
  pageTitle: 'Personal Finance Community - Seedly',
  ogType: 'website',
  ogTitle: 'Personal Finance Community - Seedly',
  ogDescription:
    'Join the discussion on personal finance question and answer community. Be more financially literate.',
  ogImage: `${buildConfig.s3BucketEndpoint}/share/questions.png`,
  keywords:
    'personal finance community, hacks, advice, questions, answers, investments, deals, savings, singapore',
};

const questionDetailSeoMeta = {
  ogType: 'article',
  ogDescription:
    'Join the discussion on personal finance question and answer community. Be more financially literate.',
  ogImage: `${buildConfig.s3BucketEndpoint}/share/questions.png`,
};

const reviewsSeoMeta = {
  ogType: 'website',
  ogTitle: 'Compare and Review Financial Products in Singapore - Seedly',
  ogDescription:
    'Read reviews from other users on Robo-Advisors, investment products and more',
  ogImage: `${buildConfig.s3BucketEndpoint}/share/reviews.png`,
  keywords:
    'financial product reviews, feedback, comparison, financial products, deals, singapore, personal finance, investment, investing, seedly',
};

const levelsSeoMeta = {
  ogType: 'website',
  ogTitle: 'Levels and Badges - Seedly',
  ogImage: `${buildConfig.s3BucketEndpoint}/share/og.png`,
  ogDescription:
    'Be recognised and level up through meaningful contribution of questions, answers and reviews!',
  keywords: 'personal finance, financial product reviews, track expenses',
};

export {
  defaultSeoMeta,
  questionDetailSeoMeta,
  homeSeoMeta,
  appSeoMeta,
  reviewsSeoMeta,
  qnaSeoMeta,
  levelsSeoMeta,
};
