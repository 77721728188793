import map from 'lodash/map';
import omit from 'lodash/omit';
import get from 'lodash/get';
import find from 'lodash/find';
import { CREDENTIAL_TYPE } from 'app/constants/profile';
import { generateCredentialText } from './formatCredentials';
import {
  createOrganisationJsonLd,
  createQuestionsAnswersJsonLd,
  createOpinionJsonLd,
  createProductReviewJsonLd,
  createProfileJsonLd,
  createSignUpBonusProductsJsonLd,
} from './createJsonLd';
import {
  defaultSeoMeta,
  homeSeoMeta,
  qnaSeoMeta,
  questionDetailSeoMeta,
  reviewsSeoMeta,
  levelsSeoMeta,
} from './seoMetaConfig';
import buildConfig from './buildConfig';

export const configureRewardDetailsPageHead = (reward = null) => {
  let props = {};

  try {
    if (reward) {
      props.pageTitle = `${reward.title} - `;
      props.ogUrl = `${buildConfig.apiHost}/rewards?id=${reward.id}/`;
      props.ogType = 'website';
      props.ogTitle = reward.title;
      props.ogImage = reward.banner;
      props.ogDescription = reward.description;
    }
  } catch (error) {
    props = {};
  }

  return props;
};

export const configureQuestionDetailsPageHead = (question, jsonLdAnswers) => {
  let props = {};

  try {
    let canonicalSlug = '';

    // set child conical slug to parent's question slug
    if (question.isMerged && question.isChild) {
      const parentQuestion = get(question, 'parentQuestion', null);
      canonicalSlug = parentQuestion ? parentQuestion.slug : question.slug;
    } else {
      canonicalSlug = question.slug;
    }

    props.pageTitle = `${question.title} - `;
    props.ogUrl = `${buildConfig.apiHost}/posts/${question.slug}/`;
    props.ogType = 'article';
    props.ogTitle = question.title;
    props.ogImage = questionDetailSeoMeta.ogImage;
    props.ogDescription =
      question.description || questionDetailSeoMeta.ogDescription;
    props.ogUpdatedTime = question.updatedAt;
    props.keywords = map(question.tags, 'value').join(', ');
    props.canonicalUrl = `${buildConfig.apiHost}/posts/${canonicalSlug}/`;
    props.jsonLd = createQuestionsAnswersJsonLd(question, jsonLdAnswers);
  } catch (error) {
    props = {};
  }

  return props;
};

export const configureOpinionDetailPageHead = opinion => {
  let props = {};

  try {
    props.pageTitle = `${opinion.title} - `;
    props.ogUrl = `${buildConfig.apiHost}/opinions/${opinion.slug}/`;
    props.ogTitle = opinion.title;
    props.ogImage = opinion.image || qnaSeoMeta.ogImage;
    props.ogDescription = opinion.description;
    props.ogUpdatedTime = opinion.updatedAt;
    props.keywords = map(opinion.post.tags, 'value').join(', ');
    props.canonicalUrl = `${buildConfig.apiHost}/opinions/${opinion.slug}/`;
    props.jsonLd = createOpinionJsonLd(opinion);
  } catch (error) {
    props = {};
  }

  return props;
};

export const configureReviewPageHead = (category, bankSlug = '') => {
  let props = {};

  try {
    const today = new Date();
    const monthAndYear = today.toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
    });
    const year = today.toLocaleDateString('en-US', { year: 'numeric' });
    if (category) {
      props.pageTitle = category.metaTitle
        ? `${category.metaTitle} - `
        : `Best ${category.name} in Singapore ${year} - `;
      props.ogUrl = `${buildConfig.apiHost}/reviews/${category.slug}/`;
      props.ogType = 'website';
      props.ogTitle =
        category.metaTitle || `Best ${category.name} in Singapore ${year} - `;
      const desc = `Compare and read real user reviews on ${category.name} in Singapore - ${monthAndYear}`;
      props.ogDescription = category.metaDescription
        ? category.metaDescription
        : desc;
      props.ogImage = reviewsSeoMeta.ogImage;
      props.keywords = `${category.name}, ${reviewsSeoMeta.keywords}`;
      props.canonicalUrl = `${buildConfig.apiHost}/reviews/${category.slug}/${
        bankSlug && `${bankSlug}/`
      }`;
    } else {
      props.pageTitle = `All Products - `;
      props.ogUrl = `${buildConfig.apiHost}/reviews`;
      props.ogType = 'website';
      props.ogTitle = 'All Products';
      props.ogDescription = reviewsSeoMeta.ogDescription;
      props.ogImage = reviewsSeoMeta.ogImage;
      props.keywords = reviewsSeoMeta.keywords;
      props.canonicalUrl = `${buildConfig.apiHost}/reviews/`;
    }
  } catch (error) {
    props = {};
  }

  return props;
};

export const configureSignUpBonusPageHead = data => {
  let props = {};

  try {
    if (data.seoMeta?.category) {
      props.pageTitle = `${data.title} - `;
      props.ogUrl = `${buildConfig.apiHost}/reviews/sign-up-bonus/${data.seoMeta.category.slug}/`;
      props.ogType = 'website';
      props.ogTitle = `${data.title} - `;
      props.ogDescription = data.description;
      props.keywords = `${data.seoMeta.category.name}, ${reviewsSeoMeta.keywords}`;
      props.canonicalUrl = `${buildConfig.apiHost}/reviews/sign-up-bonus/${data.seoMeta.category.slug}/`;
      props.jsonLd = createSignUpBonusProductsJsonLd(data.products);
    } else {
      props.pageTitle = 'Sign Up Bonus - ';
      props.ogUrl = `${buildConfig.apiHost}/reviews/sign-up-bonus/`;
      props.ogType = 'website';
      props.ogTitle = 'Sign Up Bonus';
      props.ogDescription =
        'Read reviews from other users on Robo-Advisors, investment products and more';
      props.keywords = reviewsSeoMeta.keywords;
      props.canonicalUrl = `${buildConfig.apiHost}/reviews/sign-up-bonus/`;
      props.jsonLd = createSignUpBonusProductsJsonLd(data.products);
    }
  } catch (error) {
    props = {};
  }
  return props;
};

export const configureProductDetailPageHead = (
  category = null,
  product = null,
  reviews = [],
  queryReview = null,
) => {
  let props = {};
  try {
    const today = new Date();
    const monthYear = today.toLocaleString('default', {
      month: 'short',
      year: 'numeric',
    });

    let ogTitle =
      get(product, 'details.meta.title', '') ||
      `${product.name} Reviews and Comparison - Seedly.sg`;
    let ogDescription =
      product.reviewsCount > 0
        ? `Read ${product.reviewsCount} unbiased reviews of ${product.name} 2021 on Seedly community`
        : `Be the first to leave a review on ${product.name} `;

    if (queryReview) {
      ogTitle = `${queryReview.username}'s review of ${product.name}`;
      ogDescription = queryReview.reviewText;
    }

    props.pageTitle = `${product.name} Ratings ${monthYear}`;
    props.pageTitleHasPostfix = false;
    const url = `${buildConfig.apiHost}/reviews/${category.slug}/${product.slug}/`;
    props.ogUrl = url;
    props.ogType = 'website';
    props.ogTitle = ogTitle;
    props.ogDescription = ogDescription;
    props.ogImage = product.logo;
    props.keywords =
      get(product, 'details.meta.keywords', '') ||
      get(product, 'company.details.meta.keywords', '');
    props.description = get(product, 'details.meta.description', '');
    props.canonicalUrl = url;
    props.jsonLd = createProductReviewJsonLd(product, reviews, category);
  } catch (error) {
    props = {};
  }

  return props;
};

export const configureProfilePageHead = (profile = {}, latestCredential) => {
  const { name, slug, image } = profile;
  let props = {};

  try {
    props.pageTitle = name && `${name} - `;
    props.ogUrl = `${buildConfig.apiHost}/profile/${slug}/`;
    props.ogType = 'profile';
    props.ogTitle = name;
    props.ogImage = image;
    props.ogDescription = generateCredentialText(latestCredential.overall);
    props.jsonLd = createProfileJsonLd(
      name,
      latestCredential[CREDENTIAL_TYPE.EMPLOYMENT],
    );
    props.isNoIndex = true;
  } catch (error) {
    props = {};
  }

  return props;
};

export const configureHomePageHead = () => {
  let props = {};

  try {
    const name = 'Seedly - Singapore’s Biggest Personal Finance Community';
    props.pageTitle = name;
    props.ogUrl = buildConfig.apiHost;
    props.ogTitle = name;
    props.ogDescription =
      'Make smarter financial decisions with Seedly today! Get financial insights from the community and read content. You can also read real user reviews on financial products too!';
    props.pageTitleHasPostfix = false;
    props.jsonLd = createOrganisationJsonLd();
  } catch (error) {
    props = {};
  }

  return { ...props, ...homeSeoMeta };
};

export const configureLevelsPageHead = imageUrl => {
  let props = {};

  try {
    props.pageTitle = 'Levels and Badges - ';
    props.ogTitle = 'Levels and Badges - Seedly';
    props.ogDescription =
      'Seedly is powered by our community of passionate enthusiastic and expert users just like you. Level up your game, help add value to fellow members with your knowledge and experience.';
    props.ogUrl = `${buildConfig.apiHost}/community/badges-and-levels`;
    props.ogImage = imageUrl;
  } catch (error) {
    props = {};
  }

  return { ...props, ...levelsSeoMeta };
};

export const configureCampaignsPageHead = (pageHead, image, slug) => {
  const title = get(find(pageHead, ['type', 'heading1']), 'text', '');
  const description = get(find(pageHead, ['type', 'paragraph']), 'text', '');
  let props = {};

  try {
    props.pageTitle = `${title} - `;
    props.ogTitle = `${title} - Seedly`;
    props.ogDescription = description;
    props.ogImage = get(image, 'url', defaultSeoMeta.ogImage);
    props.ogUrl = `${buildConfig.apiHost}/c/${slug}/`;
    props.keywords =
      'personal finance, financial product reviews, track expenses';
    props.ogType = 'website';
    props.canonicalUrl = `${buildConfig.apiHost}/c/${slug}/`;
  } catch (error) {
    props = {};
  }

  return props;
};

export const configureStaticPageHead = (pageType, additionalDetails) => {
  let props = {};

  try {
    const staticPagesSeoMeta = {
      about: {
        pageTitle: 'About - ',
        ogUrl: `${buildConfig.apiHost}/about`,
        ogDescription:
          'At Seedly, we help you make smarter financial decisions with the right tools and the right mindset.',
      },
      faq: {
        pageTitle: 'FAQ - ',
        ogUrl: `${buildConfig.apiHost}/faq`,
        ogDescription:
          'At Seedly, we always aim to serve our customers with security in mind.',
      },
      privacy: {
        pageTitle: 'Privacy Statement - ',
        ogUrl: `${buildConfig.apiHost}/privacy`,
        ogDescription: `${additionalDetails}. At Seedly, we help you make smarter financial decisions with the right tools and right mindset.`,
      },
      security: {
        pageTitle: 'Security - ',
        ogUrl: `${buildConfig.apiHost}/security`,
        ogDescription:
          'Seedly is built upon the latest security systems in mobile technology today.',
      },
      terms: {
        pageTitle: 'Terms & Subscription Agreement - ',
        ogUrl: `${buildConfig.apiHost}/security`,
        ogDescription: `${additionalDetails}. Seedly is built upon the latest security systems in mobile technology today.`,
      },
      search: {
        pageTitle: 'Search Results - ',
        ogUrl: `${buildConfig.s3BucketEndpoint}/search?s=${additionalDetails}`,
      },
      savingsAccountCalculator: {
        pageTitle: `Best Savings Account Calculator in Singapore ${additionalDetails} (Beta)`,
        ogDescription:
          'Find out the best savings account with the highest rate in Singapore for you.',
      },
    };

    props = {
      ...staticPagesSeoMeta[pageType],
      ...(get(staticPagesSeoMeta[pageType], 'ogDescription', false)
        ? defaultSeoMeta
        : omit(defaultSeoMeta, 'ogDescription')),
    };
  } catch (error) {
    props = {};
  }

  return props;
};
